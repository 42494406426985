import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  ValveConnect,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  Pond,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  MultiInfo,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  InfoButton,
  MultiSetpointControl,
  MultiInfoButton,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function ValveScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;

  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(3),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(3);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const locationEquipment3 = props.locations?.locations?.[3]?.equipment;
  if (!props.locations?.locations?.[3]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Summit Express Valve House">
            <InfoControlRow>
              <Info
                title="Water Temp"
                statistic={useCelsius ? locationEquipment3?.Other?.ioValues?.discharge_water_temperature_alternate?.value : locationEquipment3?.Other?.ioValues?.discharge_water_temperature?.value}
                label={useCelsius ? locationEquipment3?.Other?.ioValues?.discharge_water_temperature_alternate?.ioValueType?.units : locationEquipment3?.Other?.ioValues?.discharge_water_temperature?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="Water Pressure"
                statistic={locationEquipment3?.Other?.ioValues?.discharge_water_pressure?.value}
                label={locationEquipment3?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
              />
              <Info
                title="Air Pressure"
                statistic={locationEquipment3?.Other?.ioValues?.discharge_air_pressure?.value}
                label={locationEquipment3?.Other?.ioValues?.discharge_air_pressure?.ioValueType?.units}
              />
              <Info
                title="Air Temp"
                statistic={useCelsius ? locationEquipment3?.Other?.ioValues?.system_air_temperature_alternate?.value : locationEquipment3?.Other?.ioValues?.system_air_temperature?.value}
                label={useCelsius ? locationEquipment3?.Other?.ioValues?.system_air_temperature_alternate?.ioValueType?.units : locationEquipment3?.Other?.ioValues?.system_air_temperature?.ioValueType?.units}
                precision={1}
              />
              <Info
                title="Room Temp"
                statistic={useCelsius ? locationEquipment3?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment3?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment3?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment3?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[3], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[3], theme)}
              />
            </InfoControlRow>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      // opacity: 100%;
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
        // opacity: ${props.navbarClosed ? '100%' : '50%'};
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s, opacity ease 0.3s; 
    `,
    hiddenMobile: css`
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    showMobile: css`
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: flex;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

ValveScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

ValveScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(ValveScreen);
