import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  ValveConnect,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  Pond,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  MultiInfo,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  InfoButton,
  MultiSetpointControl,
  MultiInfoButton,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function PrimaryScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;

  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(1),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(1);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'PRIMARY',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
  };

  const locationEquipment1 = props.locations?.locations?.[1]?.equipment;
  if (!props.locations?.locations?.[1]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Primary Pumphouse">
            <InfoControlRow>
              <MobileStatTable
                title="System Metrics"
                stats={
                [
                  {
                    description: 'Discharge Water Flow',
                    stat: locationEquipment1?.Other?.ioValues?.discharge_water_flow?.value,
                    label: locationEquipment1?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Pressure',
                    stat: locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value,
                    label: locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Water Temp.',
                    stat: useCelsius ? locationEquipment1?.Other?.ioValues?.water_temperature_alternate?.value : locationEquipment1?.Other?.ioValues?.water_temperature?.value,
                    label: useCelsius ? locationEquipment1?.Other?.ioValues?.water_temperature_alternate?.ioValueType?.units : locationEquipment1?.Other?.ioValues?.water_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]
              }
              />
              <VFDPumpControl
                title="PID"
                inputNumWidth="64px"
                pumpData={{ id: `PID_${locationEquipment1?.PID?.id}` }}
                pidData={locationEquipment1?.PID}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_mode',
                  manualMode: 'manual_mode',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 1]}
                writeValues={[0, 1]}
              />
              <ToggleControl
                title="Transfer Mode"
                button1Text="Enable"
                button2Text="Disable"
                buttonWidth="56px"
                ioData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                IOValueKeys={{
                  start: 'enable_transfer_mode',
                  stop: 'enable_transfer_mode',
                  started: 'enable_transfer_mode',
                  stopped: 'enable_transfer_mode',
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                dot
              />
              <MultiSetpointControl
                title="Pump Setpoints"
                styles="padding: 2px 32px 2px 10px;"
                pidData={locationEquipment1?.['P5 Transfer']}
                labels={['P5 On Time', 'P5 Off Time']}
                statistics={[
                  `${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_enable_setpoint?.value}`,
                  `${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_stop_setpoint?.value}`,
                ]}
                units={[
                  `${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_enable_setpoint?.ioValueType?.units}`,
                  `${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_stop_setpoint?.ioValueType?.units}`,
                ]}
                setIOValue={props.setIOValue}
                IOValueIDs={[
                  `${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_enable_setpoint?.id}`,
                  `${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_stop_setpoint?.id}`,
                ]}
                precision={[0, 0]}
                stats
                statsAfter
                statsTitles={['P5 On: ', 'P5 Off: ']}
                statsStats={[`${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_enabled_time?.value}`,
                  `${locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_stopped_time?.value}`]}
                statsLabels={[locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_enabled_time?.ioValueType?.units,
                  locationEquipment1?.['P5 Transfer']?.ioValues?.transfer_stopped_time?.ioValueType?.units]}
                precisions={[0, 0]}
              />
              <MultiInfo
                title="Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment1?.Other?.ioValues?.pump_starting_timer?.value,
                  locationEquipment1?.Other?.ioValues?.pump_stopping_timer?.value,
                ]}
                ids={[
                  locationEquipment1?.Other?.ioValues?.pump_starting_timer?.id,
                  locationEquipment1?.Other?.ioValues?.pump_stopping_timer?.id,
                ]}
                labels={[
                  locationEquipment1?.Other?.ioValues?.pump_starting_timer?.ioValueType?.units,
                  locationEquipment1?.Other?.ioValues?.pump_stopping_timer?.ioValueType?.units,
                ]}
              />
              <InfoButton
                title="Trip Volume"
                statistic={locationEquipment1?.FT?.ioValues?.trip_volume?.value}
                label={locationEquipment1?.FT?.ioValues?.trip_volume?.ioValueType?.units}
                precision={3}
                buttonWriteId={locationEquipment1?.FT?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <Info
                title="Total Volume"
                label={locationEquipment1?.FT?.ioValues?.total_volume?.ioValueType?.units}
                statistic={locationEquipment1?.FT?.ioValues?.total_volume?.value}
              />
              {/* <MultiInfo
                title="Snowmax"
                subtitles={['Status', 'Level']}
                statistics={[
                  locationEquipment1?.Other?.ioValues?.snomax_enabled?.value ? 'On' : 'Off',
                  locationEquipment1?.Other?.ioValues?.snomax_low_tank_level?.value ? 'Low' : 'Good',
                ]}
                colors={[
                  locationEquipment1?.Other?.ioValues?.snomax_enabled?.value ? theme.onGreen : theme.alarm,
                  locationEquipment1?.Other?.ioValues?.snomax_low_tank_level?.value ? theme.alarm : theme.onGreen,
                ]}
                ids={[
                  locationEquipment1?.Other?.ioValues?.snomax_enabled?.id,
                  locationEquipment1?.Other?.ioValues?.snomax_low_tank_level?.id,
                ]}
              /> */}
              <Info
                title="Room Temp"
                statistic={useCelsius ? locationEquipment1?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment1?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment1?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment1?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment1?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment1?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarmReset: 'alarm_reset',
                  masterAlarm: 'master_alarm',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[1], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[1], theme)}
              />
            </InfoControlRow>
            <PumpsRowMedium>
              <PipePlaceholder hidden />
              <Pump
                noPipes
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.WP1}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'disable_enable',
                  stopped: 'disable_enable',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                }}
                alarms={[
                  locationEquipment1?.WP1?.ioValues?.fail_to_run,
                ]}
                statuses={[
                  {
                    id: locationEquipment1.WP1?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.WP1?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto Enabled',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                  {
                    id: locationEquipment1.WP1?.ioValues?.hand?.id,
                    status: locationEquipment1.WP1?.ioValues?.hand?.value,
                    statusKey: {
                      0: '',
                      1: 'Hand',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                  {
                    id: locationEquipment1.WP1?.ioValues?.auto?.id,
                    status: locationEquipment1.WP1?.ioValues?.auto?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                ]}
              />
              <Pump
                noPipes
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.WP2}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'disable_enable',
                  stopped: 'disable_enable',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                }}
                alarms={[
                  locationEquipment1?.WP2?.ioValues?.fail_to_run,
                ]}
                statuses={[
                  {
                    id: locationEquipment1.WP2?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.WP2?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto Enabled',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                  {
                    id: locationEquipment1.WP2?.ioValues?.hand?.id,
                    status: locationEquipment1.WP2?.ioValues?.hand?.value,
                    statusKey: {
                      0: '',
                      1: 'Hand',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                  {
                    id: locationEquipment1.WP2?.ioValues?.auto?.id,
                    status: locationEquipment1.WP2?.ioValues?.auto?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                ]}
              />
              <Pump
                noPipes
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.WP3}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'disable_enable',
                  stopped: 'disable_enable',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                }}
                alarms={[
                  locationEquipment1?.WP3?.ioValues?.fail_to_run,
                ]}
                statuses={[
                  {
                    id: locationEquipment1.WP3?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.WP3?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto Enabled',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                  {
                    id: locationEquipment1.WP3?.ioValues?.hand?.id,
                    status: locationEquipment1.WP3?.ioValues?.hand?.value,
                    statusKey: {
                      0: '',
                      1: 'Hand',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.local,
                    },
                  },
                  {
                    id: locationEquipment1.WP3?.ioValues?.auto?.id,
                    status: locationEquipment1.WP3?.ioValues?.auto?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.manual,
                    },
                  },
                ]}
              />
              <PipePlaceholder hidden />
              <PipePlaceholder hidden />
            </PumpsRowMedium>
            <PumpsRowMedium>
              <Pump
                pipeAbove="right"
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P6}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'enabled',
                  stopped: 'enabled',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  // speed: 'speed',
                }}
                alarms={[
                  locationEquipment1?.P6?.ioValues?.high_motor_temperature,
                  locationEquipment1?.P6?.ioValues?.low_suction_level,
                  locationEquipment1?.P6?.ioValues?.fail_to_run,
                  locationEquipment1?.P6?.ioValues?.speed_signal_failure,
                  locationEquipment1?.P6?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P6?.ioValues?.low_suction_level_fault,
                  locationEquipment1?.P6?.ioValues?.modbus_communication_error,
                ]}
                statuses={[
                  {
                    id: locationEquipment1?.P6?.ioValues?.status?.id,
                    status: locationEquipment1?.P6?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment1.P6?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.P6?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P6?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.P6?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P6?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment1?.P6?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P4}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'enabled',
                  stopped: 'enabled',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  // speed: 'speed',
                }}
                alarms={[
                  locationEquipment1?.P4?.ioValues?.high_motor_temperature,
                  locationEquipment1?.P4?.ioValues?.low_suction_level,
                  locationEquipment1?.P4?.ioValues?.fail_to_run,
                  locationEquipment1?.P4?.ioValues?.speed_signal_failure,
                  locationEquipment1?.P4?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P4?.ioValues?.low_suction_level_fault,
                  locationEquipment1?.P4?.ioValues?.modbus_communication_error,
                ]}
                statuses={[
                  {
                    id: locationEquipment1?.P4?.ioValues?.status?.id,
                    status: locationEquipment1?.P4?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment1.P4?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.P4?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment1?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P3}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'enabled',
                  stopped: 'enabled',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  // speed: 'speed',
                }}
                alarms={[
                  locationEquipment1?.P3?.ioValues?.high_motor_temperature,
                  locationEquipment1?.P3?.ioValues?.low_suction_level,
                  locationEquipment1?.P3?.ioValues?.fail_to_run,
                  locationEquipment1?.P3?.ioValues?.speed_signal_failure,
                  locationEquipment1?.P3?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P3?.ioValues?.low_suction_level_fault,
                  locationEquipment1?.P3?.ioValues?.modbus_communication_error,
                ]}
                statuses={[
                  {
                    id: locationEquipment1?.P3?.ioValues?.status?.id,
                    status: locationEquipment1?.P3?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment1.P3?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.P3?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment1?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P2}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'enabled',
                  stopped: 'enabled',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  // speed: 'speed',
                }}
                alarms={[
                  locationEquipment1?.P2?.ioValues?.high_motor_temperature,
                  locationEquipment1?.P2?.ioValues?.low_suction_level,
                  locationEquipment1?.P2?.ioValues?.fail_to_run,
                  locationEquipment1?.P2?.ioValues?.speed_signal_failure,
                  locationEquipment1?.P2?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P2?.ioValues?.low_suction_level_fault,
                  locationEquipment1?.P2?.ioValues?.modbus_communication_error,
                ]}
                statuses={[
                  {
                    id: locationEquipment1?.P2?.ioValues?.status?.id,
                    status: locationEquipment1?.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment1.P2?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.P2?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment1?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment1?.P1}
                otherData={locationEquipment1?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'enabled',
                  stopped: 'enabled',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment1?.P1?.ioValues?.high_motor_temperature,
                  locationEquipment1?.P1?.ioValues?.low_suction_level,
                  locationEquipment1?.P1?.ioValues?.fail_to_run,
                  locationEquipment1?.P1?.ioValues?.speed_signal_failure,
                  locationEquipment1?.P1?.ioValues?.high_seal_temperature,
                  locationEquipment1?.P1?.ioValues?.low_suction_level_fault,
                  locationEquipment1?.P1?.ioValues?.modbus_communication_error,
                ]}
                statuses={[
                  {
                    id: locationEquipment1?.P1?.ioValues?.status?.id,
                    status: locationEquipment1?.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment1.P1?.ioValues?.auto_enabled?.id,
                    status: locationEquipment1.P1?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment1?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment1?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment1?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment1?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={styles.flexRowBox}>
                <Pump
                  pipeAbove="middle"
                  percent
                  controlPositionTop="-53px"
                  controlWidth="98px"
                  componentMinWidth="0px"
                  buttonsMinWidth="42px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment1?.P5}
                  otherData={locationEquipment1?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  writeValues={[1, 0]}
                  readValues={[1, 0]}
                  IOValueKeys={{
                    started: 'enabled',
                    stopped: 'enabled',
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    // speed: 'speed',
                  }}
                  alarms={[
                    locationEquipment1?.P5?.ioValues?.high_motor_temperature,
                    locationEquipment1?.P5?.ioValues?.low_suction_level,
                    locationEquipment1?.P5?.ioValues?.fail_to_run,
                    locationEquipment1?.P5?.ioValues?.speed_signal_failure,
                    locationEquipment1?.P5?.ioValues?.high_seal_temperature,
                    locationEquipment1?.P5?.ioValues?.low_suction_level_fault,
                    locationEquipment1?.P5?.ioValues?.modbus_communication_error,
                  ]}
                  statuses={[
                    {
                      id: locationEquipment1?.P5?.ioValues?.status?.id,
                      status: locationEquipment1?.P5?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment1.P5?.ioValues?.auto_enabled?.id,
                      status: locationEquipment1.P5?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: 'Auto',
                      },
                      statusColorKey: {
                        0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                        1: theme.auto,
                      },
                    },
                  ]}
                  stats={[
                    {
                      stat: locationEquipment1?.P5?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment1?.P5?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment1?.P5?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment1?.P5?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={[css`margin-top: -3px; display: flex; flex-direction: row;`, styles.hiddenMobile]}>
                  <Statistic
                    statistic={useCelsius ? locationEquipment1?.Other?.ioValues?.water_temperature_alternate?.value : locationEquipment1?.Other?.ioValues?.water_temperature?.value}
                    border
                    label={useCelsius ? locationEquipment1?.Other?.ioValues?.water_temperature_alternate?.ioValueType?.units : locationEquipment1?.Other?.ioValues?.water_temperature?.ioValueType?.units}
                    precision={1}
                  />
                  <Statistic
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.value}
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                  />
                  <Statistic
                    statistic={locationEquipment1?.Other?.ioValues?.discharge_water_flow?.value}
                    border
                    label={locationEquipment1?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                  />
                </div>
                <PipePlaceholder minWidth="0px" horizontalAbove capAfter capLabel="To Booster" capLabelLeft="-62px" capLabelTop="15px" />
              </div>
            </PumpsRowMedium>
            <PumpsRowExtraSmall>
              <Pond
                color={theme.pipeColors.water}
                id={`Lake_Info_${locationEquipment1?.id}`}
              >
                <Info
                  title="Water Level"
                  statistic={locationEquipment1?.Other?.ioValues?.water_level?.value}
                  precision={1}
                  label={locationEquipment1?.Other?.ioValues?.water_level?.ioValueType?.units}
                />
              </Pond>
            </PumpsRowExtraSmall>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      // opacity: 100%;
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
        // opacity: ${props.navbarClosed ? '100%' : '50%'};
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s, opacity ease 0.3s; 
    `,
    hiddenMobile: css`
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    showMobile: css`
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: flex;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

PrimaryScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

PrimaryScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(PrimaryScreen);
