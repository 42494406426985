import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTheme, css } from '@emotion/react';
import { FaCompressAlt, FaExpandAlt } from 'react-icons/fa';

import {
  Pump,
  Valve,
  Pipe,
  PipePlaceholder,
  MiddlePipeL,
  ValveConnect,
  WaterTank,
  AirTank,
  Compressor,
  TriplePipeFork,
  Pond,
  DoublePipeFork,
  PipeFork,
  ConnectionIndicator,
} from 'isno/lib/components/equipment';

import {
  BasicRow,
  InfoControlRow,
  PumpsRowMedium,
  PumpsRow,
  PumpsRowSmall,
  PumpsRowExtraSmall,
  LabeledContainer,
} from 'isno/lib/components/layout';

import {
  Weather,
  Info,
  MultiColInfo,
  // Totals,
  Statistic,
  MobileStatTable,
  PLCStateRouter,
  MultiInfo,
  PLCColorRouter,
  LoadingIndicator,
  Alarms,
} from 'isno/lib/components/displays';

import {
  AutoValveControl,
  VFDPumpControl,
  ToggleControl,
  AlarmReset,
  InfoButton,
  MultiSetpointControl,
  MultiInfoButton,
} from 'isno/lib/components/controls';

import { startCase } from 'lodash';

import PLC from 'isno/lib/static/images/PLC.png';
import { LanguageContext } from 'isno/lib/languages/LanguageContext';
import { fetchLocation, setIOValue, fetchPLCs } from '../actions';

// Should maintain one large key that I pass in to each component
// and can configure from pumphouse to pumphouse
const IOValueKeys = {
  // Building Data
  buildingTemp: 'building_temperature',
  // Water Flow Source
  cityTankMode: 'city_tank_mode',
  // Water Line Data
  waterFlowRate: 'water_flow',
  suctionWaterPressure: 'suction_water_pressure',
  line3WaterFlow: 'line_3_water_flow',
  line2WaterFlow: 'line_2_water_flow',
  // Air Line Data
  line2AirFlow: 'line_2_air_flow',
  line2AirTemp: 'line_2_air_temperature',
  line3AirFlow: 'line_3_air_flow',
  line3AirTemp: 'line_3_air_temperature',
  dischargeAirPressure: 'discharge_air_pressure',
  // Pump Data
  speed: 'speed',
  dischargePressure: 'discharge_water_pressure',
  dischargeTemperature: 'discharge_water_temperature',
  amps: 'current',
  hours: 'run_time',
  // Pump Alarms
  warning: 'warning',
  fault: 'fault',
  lowAmps: 'low_amps',
  masterAlarm: 'master_alarm',
  lowSuctionPressure: 'low_suction_pressure',
  emergencyStop: 'emergency_stop',
  // Valve Control
  openClose: 'open_close',
  // Valve Alarms
  // v1OpeningError: 'V1_opening_error',
  // v1ClosingError: 'V1_closing_error',
};

function BoosterScreen(props) {
  const theme = useTheme();
  const { language } = useContext(LanguageContext);
  const { navbarClosed } = props;

  const styles = stylesFromTheme(theme, { navbarClosed });

  let oneSecondIntervalId;

  const oneSecondInterval = () => {
    oneSecondIntervalId = setTimeout(async () => {
      const promises = [
        props.fetchLocation(2),
        props.fetchPLCs(),
      ];
      await Promise.all(promises);
      if (oneSecondIntervalId) { // intervalId is null if component has unmounted
        oneSecondIntervalId = setTimeout(oneSecondInterval, 1000);
      }
    }, 1000);
  };

  useEffect(() => {
    // Async function to asynchronously request all of our data but then wait for
    // all of the responses before sending the next request. We use
    // this to make sure we aren't flooding our server with requests.
    props.fetchLocation(2);
    props.fetchPLCs();
    oneSecondInterval();
    return () => {
      clearTimeout(oneSecondIntervalId);
      oneSecondIntervalId = null; // needed to prevent race condition with promise
    };
  }, []);

  const pumpMainStatusKey = {
    0: 'READY',
    1: 'RUN',
    2: 'Switch',
    3: 'High Temp',
    4: 'Low Level',
    5: 'Run Fail',
    6: 'Disabled',
    7: 'Comm',
    8: 'Local',
    9: 'Fault',
    10: 'Suction',
    11: 'PRIMARY',
  };

  const pumpMainStatusColorKey = {
    0: `${theme.onGreen}`,
    1: `${theme.onGreen}`,
    2: `${theme.alarm}`,
    3: `${theme.alarm}`,
    4: `${theme.alarm}`,
    5: `${theme.alarm}`,
    6: `${theme.alarm}`,
    7: `${theme.alarm}`,
    8: `${theme.alarm}`,
    9: `${theme.alarm}`,
    10: `${theme.alarm}`,
    11: `${theme.onGreen}`,
  };

  const locationEquipment2 = props.locations?.locations?.[2]?.equipment;
  if (!props.locations?.locations?.[2]) {
    return (
      <div
        css={[
          styles.loadingContainer,
          props.navbarClosed ? styles.navClosedPadding : styles.navOpenPadding,
        ]}
      >
        <div css={css`position: relative; width: 100%; height: 100%;`}>
          <LoadingIndicator visible zIndex={4} />
        </div>
      </div>
    );
  }

  const useCelsius = props.settings?.settings?.useCelsius;

  // TODO: fix style names... maybe use styled components
  return (
    <div css={[styles.fullscreen, styles.hideScrollbar]}>
      <div css={css`min-height: 0px;`}>
        <BasicRow styles="min-width: fit-content; min-height: fit-content;">
          <LabeledContainer border backgroundColor={theme.backgroundColor} label="Booster Pumphouse">
            <InfoControlRow>
              <MobileStatTable
                title="System Metrics"
                stats={
                [
                  {
                    description: 'Discharge Water Flow',
                    stat: locationEquipment2?.Other?.ioValues?.discharge_water_flow?.value,
                    label: locationEquipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units,
                  },
                  {
                    description: 'Discharge Water Pressure',
                    stat: locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.value,
                    label: locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Suction Water Pressure',
                    stat: locationEquipment2?.Other?.ioValues?.suction_water_pressure?.value,
                    label: locationEquipment2?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units,
                  },
                  {
                    description: 'Water Temp.',
                    stat: useCelsius ? locationEquipment2?.Other?.ioValues?.water_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.water_temperature?.value,
                    label: useCelsius ? locationEquipment2?.Other?.ioValues?.water_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.water_temperature?.ioValueType?.units,
                    precision: 1,
                  },
                ]
              }
              />
              <VFDPumpControl
                title="PID"
                inputNumWidth="64px"
                pumpData={{ id: `PID_${locationEquipment2?.PID?.id}` }}
                pidData={locationEquipment2?.PID}
                setIOValue={props.setIOValue}
                max="1500"
                IOValueKeys={{
                  autoMode: 'auto_mode',
                  manualMode: 'manual_mode',
                  autoManual: 'auto_manual',
                  autoSetpoint: 'auto_setpoint',
                  manualSetpoint: 'manual_setpoint',
                }}
                readValues={[1, 1]}
                writeValues={[0, 1]}
              />
              <Info
                title="PID Fill Mode"
                statistic={locationEquipment2?.PID?.ioValues?.fill_mode_done?.value === 1 ? 'Done' : 'Filling'}
                color={locationEquipment2?.PID?.ioValues?.fill_mode_done?.value === 1 ? theme.onGreen : theme.waterColor}
              />
              <MultiInfo
                title="Pump Timers"
                subtitles={['Next Start', 'Next Stop']}
                statistics={[
                  locationEquipment2?.Other?.ioValues?.pump_starting_timer?.value,
                  locationEquipment2?.Other?.ioValues?.pump_stopping_timer?.value,
                ]}
                colors={[
                  locationEquipment2?.Other?.ioValues?.pump_starting?.value ? theme.onGreen : theme.offRed,
                  locationEquipment2?.Other?.ioValues?.pump_stopping?.value ? theme.onGreen : theme.offRed,
                ]}
                ids={[
                  locationEquipment2?.Other?.ioValues?.pump_starting_timer?.id,
                  locationEquipment2?.Other?.ioValues?.pump_stopping_timer?.id,
                ]}
                labels={[
                  locationEquipment2?.Other?.ioValues?.pump_starting_timer?.ioValueType?.units,
                  locationEquipment2?.Other?.ioValues?.pump_stopping_timer?.ioValueType?.units,
                ]}
              />
              <MultiColInfo
                title="Discharge Flow Volumes"
                firstColSubtitles={['Today', 'Monthly', 'Yearly']}
                secondColSubtitles={['Yesterday', 'Last Month', 'Last Year']}
                thirdColSubtitles={['Total']}
                firstColStatistics={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.daily_volume?.value,
                  locationEquipment2?.['Discharge FT']?.ioValues?.monthly_volume?.value,
                  locationEquipment2?.['Discharge FT']?.ioValues?.yearly_volume?.value,
                ]}
                secondColStatistics={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.yesterday_volume?.value,
                  locationEquipment2?.['Discharge FT']?.ioValues?.last_month_volume?.value,
                  locationEquipment2?.['Discharge FT']?.ioValues?.last_year_volume?.value,
                ]}
                thirdColStatistics={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.total_volume?.value,
                ]}
                firstColLabels={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.daily_volume?.ioValueType?.units,
                  locationEquipment2?.['Discharge FT']?.ioValues?.monthly_volume?.ioValueType?.units,
                  locationEquipment2?.['Discharge FT']?.ioValues?.yearly_volume?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.yesterday_volume?.ioValueType?.units,
                  locationEquipment2?.['Discharge FT']?.ioValues?.last_month_volume?.ioValueType?.units,
                  locationEquipment2?.['Discharge FT']?.ioValues?.last_year_volume?.ioValueType?.units,
                ]}
                thirdColLabels={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.daily_volume?.id,
                  locationEquipment2?.['Discharge FT']?.ioValues?.monthly_volume?.id,
                  locationEquipment2?.['Discharge FT']?.ioValues?.yearly_volume?.id,
                ]}
                secondColIds={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.yesterday_volume?.id,
                  locationEquipment2?.['Discharge FT']?.ioValues?.last_month_volume?.id,
                  locationEquipment2?.['Discharge FT']?.ioValues?.last_year_volume?.id,
                ]}
                thirdColIds={[
                  locationEquipment2?.['Discharge FT']?.ioValues?.total_volume?.id,
                ]}
                firstColPrecisions={[0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
                thirdColPrecisions={[0]}
              />
              <InfoButton
                title="Discharge Trip Volume"
                statistic={locationEquipment2?.['Discharge FT']?.ioValues?.trip_volume?.value}
                label={locationEquipment2?.['Discharge FT']?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment2?.['Discharge FT']?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <MultiColInfo
                title="Supply Flow Volumes"
                firstColSubtitles={['Today', 'Monthly', 'Yearly']}
                secondColSubtitles={['Yesterday', 'Last Month', 'Last Year']}
                thirdColSubtitles={['Total']}
                firstColStatistics={[
                  locationEquipment2?.['Supply FT']?.ioValues?.daily_volume?.value,
                  locationEquipment2?.['Supply FT']?.ioValues?.monthly_volume?.value,
                  locationEquipment2?.['Supply FT']?.ioValues?.yearly_volume?.value,
                ]}
                secondColStatistics={[
                  locationEquipment2?.['Supply FT']?.ioValues?.yesterday_volume?.value,
                  locationEquipment2?.['Supply FT']?.ioValues?.last_month_volume?.value,
                  locationEquipment2?.['Supply FT']?.ioValues?.last_year_volume?.value,
                ]}
                thirdColStatistics={[
                  locationEquipment2?.['Supply FT']?.ioValues?.total_volume?.value,
                ]}
                firstColLabels={[
                  locationEquipment2?.['Supply FT']?.ioValues?.daily_volume?.ioValueType?.units,
                  locationEquipment2?.['Supply FT']?.ioValues?.monthly_volume?.ioValueType?.units,
                  locationEquipment2?.['Supply FT']?.ioValues?.yearly_volume?.ioValueType?.units,
                ]}
                secondColLabels={[
                  locationEquipment2?.['Supply FT']?.ioValues?.yesterday_volume?.ioValueType?.units,
                  locationEquipment2?.['Supply FT']?.ioValues?.last_month_volume?.ioValueType?.units,
                  locationEquipment2?.['Supply FT']?.ioValues?.last_year_volume?.ioValueType?.units,
                ]}
                thirdColLabels={[
                  locationEquipment2?.['Supply FT']?.ioValues?.total_volume?.ioValueType?.units,
                ]}
                firstColIds={[
                  locationEquipment2?.['Supply FT']?.ioValues?.daily_volume?.id,
                  locationEquipment2?.['Supply FT']?.ioValues?.monthly_volume?.id,
                  locationEquipment2?.['Supply FT']?.ioValues?.yearly_volume?.id,
                ]}
                secondColIds={[
                  locationEquipment2?.['Supply FT']?.ioValues?.yesterday_volume?.id,
                  locationEquipment2?.['Supply FT']?.ioValues?.last_month_volume?.id,
                  locationEquipment2?.['Supply FT']?.ioValues?.last_year_volume?.id,
                ]}
                thirdColIds={[
                  locationEquipment2?.['Supply FT']?.ioValues?.total_volume?.id,
                ]}
                firstColPrecisions={[0, 0, 0]}
                secondColPrecisions={[0, 0, 0]}
                thirdColPrecisions={[0]}
              />
              <InfoButton
                title="Supply Trip Volume"
                statistic={locationEquipment2?.['Supply FT']?.ioValues?.trip_volume?.value}
                label={locationEquipment2?.['Supply FT']?.ioValues?.trip_volume?.ioValueType?.units}
                precision={0}
                buttonWriteId={locationEquipment2?.['Supply FT']?.ioValues?.trip_volume_reset?.id}
                buttonText="Reset"
                setIOValue={props.setIOValue}
              />
              <MultiInfo
                title="Snowmax"
                subtitles={['Status', 'Level']}
                statistics={[
                  locationEquipment2?.Other?.ioValues?.snomax_enabled?.value ? 'On' : 'Off',
                  locationEquipment2?.Other?.ioValues?.snomax_low_tank_level?.value ? 'Low' : 'Good',
                ]}
                colors={[
                  locationEquipment2?.Other?.ioValues?.snomax_enabled?.value ? theme.onGreen : theme.alarm,
                  locationEquipment2?.Other?.ioValues?.snomax_low_tank_level?.value ? theme.alarm : theme.onGreen,
                ]}
                ids={[
                  locationEquipment2?.Other?.ioValues?.snomax_enabled?.id,
                  locationEquipment2?.Other?.ioValues?.snomax_low_tank_level?.id,
                ]}
              />
              <div css={css`display: inline-grid; flex-direction: column;`}>
                <Info
                  title="Voltage"
                  statistic={locationEquipment2?.Other?.ioValues?.voltage?.value}
                  label={locationEquipment2?.Other?.ioValues?.voltage?.ioValueType?.units}
                />
                <Info
                  title="Current"
                  statistic={locationEquipment2?.Other?.ioValues?.[IOValueKeys.amps]?.value}
                  label={locationEquipment2?.Other?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units}
                />
              </div>
              <Info
                title="Room Temp"
                statistic={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.room_temperature?.value}
                label={useCelsius ? locationEquipment2?.Other?.ioValues?.room_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.room_temperature?.ioValueType?.units}
                precision={1}
              />
              <Alarms
                title="General Alarms"
                ioValues={locationEquipment2?.Other?.ioValues}
              />
              <AlarmReset
                title="System Reset"
                buttonText="Reset"
                alarmData={locationEquipment2?.Other}
                setIOValue={props.setIOValue}
                alarmKeys={{
                  masterAlarmReset: 'alarm_reset',
                  masterAlarm: 'master_alarm',
                }}
                resetWriteValue={1}
              />
              <Info
                img={PLC}
                title="PLC Status"
                statistic={PLCStateRouter(props.plcs?.plcs?.[2], language)}
                label=""
                color={PLCColorRouter(props.plcs?.plcs?.[2], theme)}
              />
            </InfoControlRow>
            <PumpsRow>
              <Pump
                pipeAbove="right"
                pipeBelow="right"
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P1}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'enabled',
                  stopped: 'enabled',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment2?.P1?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P1?.ioValues?.low_suction_pressure,
                  locationEquipment2?.P1?.ioValues?.fail_to_run,
                  locationEquipment2?.P1?.ioValues?.speed_signal_failure,
                  locationEquipment2?.P1?.ioValues?.high_motor_temperature,
                  locationEquipment2?.P1?.ioValues?.low_suction_pressure_fault,
                  locationEquipment2?.P1?.ioValues?.modbus_communication_error,
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P1?.ioValues?.status?.id,
                    status: locationEquipment2?.P1?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment2.P1?.ioValues?.auto_enabled?.id,
                    status: locationEquipment2.P1?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P1?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P1?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P1?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P1?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <Pump
                pipeAbove="middle"
                pipeBelow="middle"
                percent
                controlPositionTop="-53px"
                controlWidth="98px"
                buttonsMinWidth="42px"
                setIOValue={props.setIOValue}
                pumpData={locationEquipment2?.P2}
                otherData={locationEquipment2?.Other}
                stateColorKey={{
                  0: `${theme.secondary}`,
                  1: `${theme.onGreen}`,
                  2: `${theme.warning}`,
                  3: `${theme.alarm}`,
                }}
                writeValues={[1, 0]}
                readValues={[1, 0]}
                IOValueKeys={{
                  started: 'enabled',
                  stopped: 'enabled',
                  start: 'disable_enable',
                  stop: 'disable_enable',
                  speed: 'speed',
                }}
                alarms={[
                  locationEquipment2?.P2?.ioValues?.high_seal_temperature,
                  locationEquipment2?.P2?.ioValues?.low_suction_pressure,
                  locationEquipment2?.P2?.ioValues?.fail_to_run,
                  locationEquipment2?.P2?.ioValues?.speed_signal_failure,
                  locationEquipment2?.P2?.ioValues?.high_motor_temperature,
                  locationEquipment2?.P2?.ioValues?.low_suction_pressure_fault,
                  locationEquipment2?.P2?.ioValues?.modbus_communication_error,
                ]}
                statuses={[
                  {
                    id: locationEquipment2?.P2?.ioValues?.status?.id,
                    status: locationEquipment2?.P2?.ioValues?.status?.value,
                    statusKey: pumpMainStatusKey,
                    statusColorKey: pumpMainStatusColorKey,
                  },
                  {
                    id: locationEquipment2.P2?.ioValues?.auto_enabled?.id,
                    status: locationEquipment2.P2?.ioValues?.auto_enabled?.value,
                    statusKey: {
                      0: '',
                      1: 'Auto',
                    },
                    statusColorKey: {
                      0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                      1: theme.auto,
                    },
                  },
                ]}
                stats={[
                  {
                    stat: locationEquipment2?.P2?.ioValues?.[IOValueKeys.amps]?.value,
                    label: locationEquipment2?.P2?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                  },
                  {
                    stat: locationEquipment2?.P2?.ioValues?.[IOValueKeys.hours]?.value,
                    label: locationEquipment2?.P2?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                  },
                ]}
              />
              <div css={[styles.flexRowBox, styles.hiddenMobile]}>
                <div css={[styles.redBox, css`margin: 20px 0px 0px 3px;`]} />
                <Pump
                  pipeAbove="middle"
                  pipeBelow="middle"
                  percent
                  viewOnly
                  controlPositionTop="-53px"
                  controlWidth="98px"
                  buttonsMinWidth="42px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment2?.P3}
                  otherData={locationEquipment2?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  writeValues={[1, 0]}
                  readValues={[1, 0]}
                  IOValueKeys={{
                    started: 'enabled',
                    stopped: 'enabled',
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    speed: 'speed',
                  }}
                  alarms={[
                    locationEquipment2?.P3?.ioValues?.high_seal_temperature,
                    locationEquipment2?.P3?.ioValues?.low_suction_pressure,
                    locationEquipment2?.P3?.ioValues?.fail_to_run,
                    locationEquipment2?.P3?.ioValues?.speed_signal_failure,
                    locationEquipment2?.P3?.ioValues?.high_motor_temperature,
                    locationEquipment2?.P3?.ioValues?.low_suction_pressure_fault,
                    locationEquipment2?.P3?.ioValues?.modbus_communication_error,
                  ]}
                  statuses={[
                    {
                      id: locationEquipment2?.P3?.ioValues?.status?.id,
                      status: locationEquipment2?.P3?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment2.P3?.ioValues?.auto_enabled?.id,
                      status: locationEquipment2.P3?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: 'Auto',
                      },
                      statusColorKey: {
                        0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                        1: theme.auto,
                      },
                    },
                  ]}
                  stats={[
                    {
                      stat: locationEquipment2?.P3?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment2?.P3?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment2?.P3?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment2?.P3?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
              </div>
              <div css={[styles.flexRowBox, styles.hiddenMobile]}>
                <div css={[styles.redBox, css`margin: 20px 0px 0px 3px;`]} />
                <Pump
                  pipeAbove="middle"
                  pipeBelow="middle"
                  percent
                  viewOnly
                  controlPositionTop="-53px"
                  controlWidth="98px"
                  componentMinWidth="0px"
                  buttonsMinWidth="42px"
                  setIOValue={props.setIOValue}
                  pumpData={locationEquipment2?.P4}
                  otherData={locationEquipment2?.Other}
                  stateColorKey={{
                    0: `${theme.secondary}`,
                    1: `${theme.onGreen}`,
                    2: `${theme.warning}`,
                    3: `${theme.alarm}`,
                  }}
                  writeValues={[1, 0]}
                  readValues={[1, 0]}
                  IOValueKeys={{
                    started: 'enabled',
                    stopped: 'enabled',
                    start: 'disable_enable',
                    stop: 'disable_enable',
                    speed: 'speed',
                  }}
                  alarms={[
                    locationEquipment2?.P4?.ioValues?.high_seal_temperature,
                    locationEquipment2?.P4?.ioValues?.low_suction_pressure,
                    locationEquipment2?.P4?.ioValues?.fail_to_run,
                    locationEquipment2?.P4?.ioValues?.speed_signal_failure,
                    locationEquipment2?.P4?.ioValues?.high_motor_temperature,
                    locationEquipment2?.P4?.ioValues?.low_suction_pressure_fault,
                    locationEquipment2?.P4?.ioValues?.modbus_communication_error,
                  ]}
                  statuses={[
                    {
                      id: locationEquipment2?.P4?.ioValues?.status?.id,
                      status: locationEquipment2?.P4?.ioValues?.status?.value,
                      statusKey: pumpMainStatusKey,
                      statusColorKey: pumpMainStatusColorKey,
                    },
                    {
                      id: locationEquipment2.P4?.ioValues?.auto_enabled?.id,
                      status: locationEquipment2.P4?.ioValues?.auto_enabled?.value,
                      statusKey: {
                        0: '',
                        1: 'Auto',
                      },
                      statusColorKey: {
                        0: theme.ofRed, // These aren't the "named" colors but I like them for this use case
                        1: theme.auto,
                      },
                    },
                  ]}
                  stats={[
                    {
                      stat: locationEquipment2?.P4?.ioValues?.[IOValueKeys.amps]?.value,
                      label: locationEquipment2?.P4?.ioValues?.[IOValueKeys.amps]?.ioValueType?.units,
                    },
                    {
                      stat: locationEquipment2?.P4?.ioValues?.[IOValueKeys.hours]?.value,
                      label: locationEquipment2?.P4?.ioValues?.[IOValueKeys.hours]?.ioValueType?.units,
                    },
                  ]}
                />
                <div css={css`display: flex; flex-direction: column; flex: 1; height: 100%;`}>
                  <div css={css`display: flex; flex-direction: row; flex: 1; height: 50%;`}>
                    <div css={[css`margin-top: -3px; display: flex; flex-direction: row;`, styles.hiddenMobile]}>
                      <Statistic
                        statistic={useCelsius ? locationEquipment2?.Other?.ioValues?.water_temperature_alternate?.value : locationEquipment2?.Other?.ioValues?.water_temperature?.value}
                        border
                        label={useCelsius ? locationEquipment2?.Other?.ioValues?.water_temperature_alternate?.ioValueType?.units : locationEquipment2?.Other?.ioValues?.water_temperature?.ioValueType?.units}
                        precision={1}
                      />
                      <Statistic
                        statistic={locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.value}
                        border
                        label={locationEquipment2?.Other?.ioValues?.discharge_water_pressure?.ioValueType?.units}
                      />
                      <Statistic
                        statistic={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.value}
                        border
                        label={locationEquipment2?.Other?.ioValues?.discharge_water_flow?.ioValueType?.units}
                      />
                    </div>
                    <PipePlaceholder minWidth="0px" horizontalAbove capAfter capLabel="Discharge" capLabelLeft="-68px" capLabelTop="17px" />
                  </div>
                  <div css={css`display: flex; flex-direction: row; flex: 1; height: 50%;`}>
                    <div css={[css`display: flex; flex-direction: column; justify-content: flex-end;`, styles.hiddenMobile]}>
                      <div css={css`display: flex; flex-direction: row; margin-bottom: -3px;`}>
                        <Statistic
                          statistic={locationEquipment2?.Other?.ioValues?.suction_water_pressure?.value}
                          border
                          label={locationEquipment2?.Other?.ioValues?.suction_water_pressure?.ioValueType?.units}
                        />
                        <Statistic
                          statistic={locationEquipment2?.Other?.ioValues?.supply_water_flow?.value}
                          border
                          label={locationEquipment2?.Other?.ioValues?.supply_water_flow?.ioValueType?.units}
                        />
                      </div>
                    </div>
                    <PipePlaceholder minWidth="0px" horizontalBelow capAfter capLabel="Suction" capLabelLeft="-52px" capLabelTop="-20px" />
                  </div>
                </div>
              </div>
            </PumpsRow>
          </LabeledContainer>
        </BasicRow>
      </div>
    </div>
  );
}

const stylesFromTheme = (theme, props) => {
  return {
    fullscreen: css`
      min-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      max-width: calc(100vw - ${props.navbarClosed ? '16px' : '160px'});
      // opacity: 100%;
      min-height: 100vh;
      max-height: 100vh;
      overflow: scroll;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        min-width: calc(100vw - 16px);
        max-width: calc(100vw - 16px);
        margin-left: 16px;
        // opacity: ${props.navbarClosed ? '100%' : '50%'};
      }
      transition: width ease 0.4s, min-width ease 0.4s, max-width ease 0.4s, opacity ease 0.3s; 
    `,
    hiddenMobile: css`
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: none;
      }
    `,
    showMobile: css`
    @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        display: flex;
      }
    `,
    flexRow: css`
      display: flex;
      flex: 1;
    `,
    flexRowBox: css`
      display: flex;
      flex: 1;
      height: 100%;
      min-width: 175px;
      position: relative;
    `,
    redBox: css`
      border: 2px dashed red;
      z-index: 3;
      background-color: rgba(0,0,0,0.5);
      position: absolute;
      top: 28px;
      left: -8px;
      width: 83%;
      height: 66%;
    `,
    hideScrollbar: css`
      overflow-y: scroll;
      ::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
        display: none;
      }
      scrollbar-width: none; /* Firefox */
    `,
    navClosedPadding: css`
      padding-left: 16px;
    `,
    navOpenPadding: css`
      padding-left: 160px;
      @media only screen and (max-width: ${theme.mobileBreakpoint}px) {
        padding-left: 16px;
      }
    `,
    loadingContainer: css`
      position: absolute;
      z-index: 4;
      top: 0px;
      left 0px;
      width: 100%;
      height: 100%;
      background-color: grey;
      transition: padding-left ease 0.4s, opacity ease 2.5s;
    `,
  };
};

BoosterScreen.propTypes = {
  navbarClosed: PropTypes.bool.isRequired,
  fetchPLCs: PropTypes.func.isRequired,
  fetchLocation: PropTypes.func.isRequired,
  setIOValue: PropTypes.func.isRequired,
  locations: PropTypes.shape({
    locations: PropTypes.shape({}),
  }),
  plcs: PropTypes.shape({
    plcs: PropTypes.shape({}),
  }),
  settings: PropTypes.shape({
    settings: PropTypes.shape({
      useCelsius: PropTypes.bool,
    }),
  }).isRequired,
};

BoosterScreen.defaultProps = {
  locations: null,
  plcs: null,
};

const mapStateToProps = (state) => ({
  navbarClosed: state.nav.navbarClosed,
  locations: state.locations,
  plcs: state.plcs,
  settings: state.settings,
});

export default connect(mapStateToProps, { fetchLocation, setIOValue, fetchPLCs })(BoosterScreen);
